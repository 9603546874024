exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coffee-index-tsx": () => import("./../../../src/pages/coffee/index.tsx" /* webpackChunkName: "component---src-pages-coffee-index-tsx" */),
  "component---src-pages-cv-en-index-tsx": () => import("./../../../src/pages/cv_en/index.tsx" /* webpackChunkName: "component---src-pages-cv-en-index-tsx" */),
  "component---src-pages-cv-index-tsx": () => import("./../../../src/pages/cv/index.tsx" /* webpackChunkName: "component---src-pages-cv-index-tsx" */),
  "component---src-pages-docs-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/docs/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-docs-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pizza-index-tsx": () => import("./../../../src/pages/pizza/index.tsx" /* webpackChunkName: "component---src-pages-pizza-index-tsx" */)
}

